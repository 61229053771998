import * as React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

const FieldShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="level" />
            <TextField source="animal_id" />
            <TextField source="point_to_finish" />
            <TextField source="price_to_upgrade" />
        </SimpleShowLayout>
    </Show>
);

export default FieldShow;
