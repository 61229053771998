import * as React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput } from 'react-admin';

const QuestEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <NumberInput source="type" disabled={true}/>
            <TextInput source="code" disabled={true}/>
            <TextInput source="content" />
            <TextInput source="link" />
            <NumberInput source="reward" />
        </SimpleForm>
    </Edit>
);

export default QuestEdit;
