import * as React from 'react';
import { List, Datagrid, TextField, EditButton, ShowButton } from 'react-admin';

const QuestList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="type" />
            <TextField source="code" />
            <TextField source="content" />
            <TextField source="link" />
            <TextField source="reward" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);

export default QuestList;
