import * as React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

const QuestShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="type" />
            <TextField source="code" />
            <TextField source="content" />
            <TextField source="link" />
            <TextField source="reward" />
        </SimpleShowLayout>
    </Show>
);

export default QuestShow;
