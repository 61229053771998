import * as React from 'react';
import { List, Datagrid, TextField, EditButton, ShowButton } from 'react-admin';

const AnimalList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="level" />
            <TextField source="point_per_tap" />
            <TextField source="price_to_upgrade" />
            <TextField source="tap_per_day" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);

export default AnimalList;
