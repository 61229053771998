import * as React from 'react';
import { ArrayField, Datagrid, Show, SimpleShowLayout, TextField } from 'react-admin';

const UserShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="username" />
            <TextField source="telegram_id" />
            <TextField source="points" />
            <TextField source="buffalo_box_count" />
            <TextField source="field_box_count" />
            <TextField source="auto_enabled" />
            <TextField source="solana_address" />
            <ArrayField source="transactions">
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="amount" />
                    <TextField source="status" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);

export default UserShow;
