import * as React from 'react';
import { List, Datagrid, TextField, EditButton, ShowButton } from 'react-admin';

const GameConfigList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="auto_price" />
            <TextField source="recipient_wallet" />
            <TextField source="buffalo_box_price" />
            <TextField source="field_box_price" />
            <TextField source="lucky_wheel_cost" />
            <TextField source="lottery_cost" />
            <TextField source="lottery_prize" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);

export default GameConfigList;
