import * as React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

const AnimalShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="level" />
            <TextField source="tap_per_day" />
            <TextField source="point_per_tap" />
            <TextField source="price_to_upgrade"/>
        </SimpleShowLayout>
    </Show>
);

export default AnimalShow;
