import * as React from 'react';
import { List, Datagrid, TextField, EmailField, EditButton, ShowButton } from 'react-admin';

const UserList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="username" />
            <TextField source="telegram_id" />
            <TextField source="points" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);

export default UserList;
