import * as React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput } from 'react-admin';

const FieldEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="level" />
            <NumberInput source="point_to_finish" />
            <NumberInput source="price_to_upgrade" />
        </SimpleForm>
    </Edit>
);

export default FieldEdit;
