import * as React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

const GameConfigShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="auto_price" />
            <TextField source="recipient_wallet" />
            <TextField source="buffalo_box_price" />
            <TextField source="field_box_price" />
            <TextField source="lucky_wheel_cost" />
            <TextField source="lottery_cost" />
            <TextField source="lottery_prize" />
        </SimpleShowLayout>
    </Show>
);

export default GameConfigShow;
