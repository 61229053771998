import * as React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput } from 'react-admin';

const GameConfigEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <NumberInput source="auto_price" />
            <TextInput source="recipient_wallet" />
            <NumberInput source="buffalo_box_price" />
            <NumberInput source="field_box_price" />
            <NumberInput source="lucky_wheel_cost" />
            <NumberInput source="lottery_cost" />
            <NumberInput source="lottery_prize" />
        </SimpleForm>
    </Edit>
);

export default GameConfigEdit;
