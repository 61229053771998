import * as React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, BooleanInput } from 'react-admin';

const UserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="username" />
            <TextInput source="telegram_id" />
            <NumberInput source="points" />
            <NumberInput source="buffalo_box_count" />
            <NumberInput source="field_box_count" />
            <BooleanInput source="auto_enabled" />
            <TextInput source="solana_address" />
        </SimpleForm>
    </Edit>
);

export default UserEdit;
