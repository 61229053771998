import * as React from 'react';
import { Admin, Resource, ListGuesser, EditGuesser, ShowGuesser } from 'react-admin';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import simpleRestProvider from 'ra-data-simple-rest';

import UserList from './users/UserList';
import UserEdit from './users/UserEdit';
import UserShow from './users/UserShow';

import AnimalList from './animals/AnimalList';
import AnimalEdit from './animals/AnimalEdit';
import AnimalShow from './animals/AnimalShow';

import FieldList from './fields/FieldList';
import FieldEdit from './fields/FieldEdit';
import FieldShow from './fields/FieldShow';

import GameConfigList from './gameConfigs/GameConfigList';
import GameConfigEdit from './gameConfigs/GameConfigEdit';
import GameConfigShow from './gameConfigs/GameConfigShow';

import QuestList from './quests/QuestList';
import QuestEdit from './quests/QuestEdit';
import QuestShow from './quests/QuestShow';


const App = () => (
    <Admin dataProvider={dataProvider} authProvider={authProvider}>
        <Resource name="users" list={UserList} edit={UserEdit} show={UserShow} />
        <Resource name="buffalo-levels" list={AnimalList} edit={AnimalEdit} show={AnimalShow} options={{
          label: 'Buffalo Levels'
        }}/>
        <Resource name="field-levels" list={FieldList} edit={FieldEdit} show={FieldShow} options={{
          label: 'Field Levels'
        }}/>
        <Resource name="game-config" list={GameConfigList} edit={GameConfigEdit} show={GameConfigShow} />
        <Resource name="quests" list={QuestList} edit={QuestEdit} show={QuestShow} />
    </Admin>
);

export default App;
